import {
  SET_LOG,
  CLEAR_LOG,
} from '../actions/log';



export default function log (state = [], action) {
  switch (action.type) {
    case SET_LOG:
      return action.events;
    case CLEAR_LOG:
      return [];
    default:
      return state;
  }
}
