import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { loginUser, logoutUser } from '../api/user';
import logger from '../lib/logger';

export const SET_AUTHED_USER = 'SET_AUTHED_USER';
export const UNSET_AUTHED_USER = 'UNSET_AUTHED_USER';



export function setAuthedUser (user) {
  return {
    type: SET_AUTHED_USER,
    user,
  }
}

export function unsetAuthedUser () {
  return {
    type: UNSET_AUTHED_USER,
  }
}

export function handleLogoutAuthedUser () {
  return (dispatch) => {
    dispatch(showLoading());
    return logoutUser()
      .then(() => {
        dispatch(unsetAuthedUser());
        dispatch(hideLoading());
      })
      .catch((err) => {
        console.log(err);
        logger.push({error: 'error logging out', err});
      })
    ;
  }
}
