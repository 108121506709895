import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';



export default function IfAdmin ({ children }) {

  const user = useSelector((store) => store.authedUser);

  return user.isAdmin ? children : <h1>Unauthorized</h1>;

}
