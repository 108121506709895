import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, ProgressBar, Carousel, Alert } from 'react-bootstrap';
import moment from 'moment';




export default function Attaboy ({maxMiles}) {

  const total = useSelector((store) => store.runs.total);
  const dayCount = useSelector((store) => {
    const runs = store.runs.runs.sort((a, b) => new Date(a.date) - new Date(b.date));
    if (runs.length === 0) return 0;
    const start = moment(runs[0].date).startOf('day');
    const end = store.runs.total.mi >= 200 ? moment(runs.slice(-1)[0].date) : moment();
    return Math.ceil(end.diff(start, 'days', true));
  });

  const name = useSelector((store) => store.authedUser.firstName);

  if (typeof total.mi !== 'number') return null;

  let level = (total.mi < maxMiles)
    ? Math.floor(total.mi / 10)
    : maxMiles / 10
  ;

  if (level === 0) return (
    <Card className='attaboy'>
      <Card.Body>
        <div className='card-text'>
          <div className='wrapper'>
            <h1>Welcome</h1>
            <p>This is your progress bar. Every 10 miles earns a new badge.</p>
            <Progress total={total} dayCount={dayCount} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );

  const reachedLevels = levels.slice(0, level);

  return (
    <Card className='attaboy'>
      <Card.Body>
        <ControlledCarousel
          level={level}
          reachedLevels={reachedLevels}
        />
        <Progress total={total} dayCount={dayCount} maxMiles={maxMiles} />
      </Card.Body>
    </Card>

  );
}



function ControlledCarousel({ level, reachedLevels }) {
  const [ activeIndex, setActiveIndex ] = useState(level - 1);
  useEffect(() => {
    setActiveIndex(level - 1);
  }, [level]);
  return (
    <Carousel
      activeIndex={activeIndex}
      onSelect={setActiveIndex}
      wrap={false}
      interval={null}
      key={level} // create a new Carousel instance when slides are added so the next/prev arrows work properly
      indicators={false}
    >
      {reachedLevels.map(({ milestone, title, description, badge, caption, width = 300 }, index) => (
        <Carousel.Item key={index}>
          <div className={`card-text level-${index+1}`}>
            <div className='wrapper'>
              <h1>{title}</h1>
              <h5>{milestone}</h5>
              <p>{description}</p>
              {index >= activeIndex -1 && index <= activeIndex + 1 &&
                <img
                  src={badge}
                  style={{maxWidth: `${width}px`}}
                />
              }
              {index === 9 &&
                <>
                  <h2>{`Congratulations${name ? ' ' + name : ''}!`}</h2>
                  <p><strong>
                    100 Mile Finisher
                  </strong></p>
                  <p>Keep going to unlock more levels every 10 miles.</p>
                  <p><em>*Have you ordered the Corona Lisa Medal to celebrate your accomplishment? <br/><a href="https://www.marathontrainingacademy.com/100-2" target="_blank">Get one here here.</a></em></p>
                </>
              }
              {caption &&
                <p>{caption}</p>
              }
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}



function Progress ({total, dayCount, maxMiles}) {
  const {mi, km} = total;
  const percent = Math.round(mi / maxMiles * 100);
  return (
    <div>
      <ProgressBar
        now={percent}
        variant='success'
      />
      {mi >= maxMiles
        ? <p className='progress-label'>You ran <strong>{mi} miles</strong> ({km} km) in <strong>{dayCount}</strong> days.</p>
        : <p className='progress-label'>You've run <strong>{mi} miles</strong> ({km} km) in <strong>{dayCount}</strong> days.</p>
      }
    </div>
  );
  return null;
}



const levels = [
  {
    milestone: '10 Miles',
    title: 'Trilobite Level',
    description: 'Congrats on reaching 10 miles! You got here much faster than a Trilobite could. We are excited to see how your fitness “evolves.”',
    badge: '/badges/1.jpg',
  },
  {
    milestone: '20 Miles',
    title: 'Mutant Fish Level',
    description: 'Nice work! Isn’t great to have legs? Think of all the places you’ll go after lockdown is over!',
    badge: '/badges/2.jpg',
  },
  {
    milestone: '30 Miles',
    title: 'Dung Beetle Level',
    description: 'You own this! Every square inch of this beautiful mess is yours. Life. Have a ball!',
    badge: '/badges/3.jpg',
  },
  {
    milestone: '40 Miles',
    title: 'Honey Badger Level',
    description: 'Nice running! Tired yet? Honey Badger don’t care.',
    badge: '/badges/4.jpg',
  },
  {
    milestone: '50 Miles',
    title: 'T-Rex Level',
    description: 'Wow, 50 miles! Your legs are strong but don’t neglect those arms.',
    badge: '/badges/5.jpg',
  },
  {
    milestone: '60 Miles',
    title: 'Endorphin Level',
    description: 'Well done endorphin lover! Happy chemicals all around!',
    badge: '/badges/6.jpg',
  },
  {
    milestone: '70 Miles',
    title: 'Hunter Gatherer Level',
    description: 'Amazing! You are really chasing down this goal.',
    badge: '/badges/7.jpg',
  },
  {
    milestone: '80 Miles',
    title: 'Pyramid Level',
    description: 'Making progress! You’re building a strong foundation that will last the test of time.',
    badge: '/badges/8.jpg',
  },
  {
    milestone: '90 Miles',
    title: 'Fortress Level',
    description: 'You have conquered 90 miles! Nothing can defeat you now (except catapults that fling fiery balls of tar, watch out for those).',
    badge: '/badges/9.jpg',
  },
  {
    milestone: '100 Miles',
    title: 'Corona Lisa Level',
    description: 'Painted in 1503 and still classy in 2021, congrats on making it to the 100 mile Corona Lisa mark!',
    badge: '/badges/10.jpg',
  },
  {
    milestone: '110 Miles',
    title: 'Perpetual Motion Machine Level',
    description: 'Nice work! You are like a perpetual motion machine: always moving but never really going anywhere (because of Covid).',
    badge: '/badges/11.jpg',
  },
  {
    milestone: '120 Miles',
    title: 'Flying Shoe Level',
    description: 'Look at you soar through this challenge like the Greek god Hermes and his winged shoes.',
    badge: '/badges/12.jpg',
  },
  {
    milestone: '130 Miles',
    title: 'Alchemy Level',
    description: 'Poof! You just created 130 miles of progress. Keep it going! Keep making the magic happen. 🙌',
    badge: '/badges/13.jpg',
  },
  {
    milestone: '140 Miles',
    title: 'Centaur Level',
    description: 'You are galloping through this run challenge, you incredible beast!',
    badge: '/badges/14.jpg',
  },
  {
    milestone: '150 Miles',
    title: 'Tankard Level',
    description: 'Congrats on making it 150 miles! According to our advanced calculations this is half way to 300.  We lift a tankard in your honor. Prost!',
    badge: '/badges/15.jpg',
  },
  {
    milestone: '160 Miles',
    title: 'Avalanche Level',
    description: 'It’s all downhill from here! Congrats on your momentum. 🙌',
    badge: '/badges/16.jpg',
  },
  {
    milestone: '170 Miles',
    title: 'Day of the Dead Level',
    description: 'Life is short and then we all gather to that post-race celebration in the sky.',
    badge: '/badges/17.jpg',
  },
  {
    milestone: '180 Miles',
    title: 'Pennsylvania Level',
    description: 'Fun fact! 180 miles is the distance from top to bottom of the U.S. state of Pennsylvania. Bet you didn’t know that! 🤓',
    badge: '/badges/18.jpg',
  },
  {
    milestone: '190 Miles',
    title: 'Limerick Level',
    description: <>We celebrate this glorious day, <br/>You bravely ran a long long way! <br/>T’was hard but still you mastered! <br/>And we salute you! (sweaty bastard)</>,
    badge: '/badges/19.jpg',
  },
  {
    milestone: '200 Miles',
    title: 'Boss Lady Level',
    description: 'Congrats on reaching 200 miles! Keep going strong and remember what coach Angie says, “YOU CAN DO HARD THINGS!”',
    badge: '/badges/20.jpg',
  },

  {
    milestone: '210 Miles',
    title: 'On Switch Level',
    description: 'Get’n it done! Your body is a machine switched to “On”.',
    badge: '/badges/21.jpg',
  },
  {
    milestone: '220 Miles',
    title: 'Electroshock Level',
    description: 'We are shocked that you made it this far. Just kidding.',
    badge: '/badges/22.jpg',
  },
  {
    milestone: '230 Miles',
    title: 'Strenuous Life Level',
    description: <>Bully! We are dee-lighted that you have made it to mile 230. <br/><br/>“It is only through labor and painful effort, by grim energy and resolute courage, that we move on to better things.”</>,
    badge: '/badges/23.jpg',
  },
  {
    milestone: '240 Miles',
    title: 'Rosie the Riveter Level',
    description: 'Nice work getting to 240 miles. You’ve got real moxie! 💪',
    badge: '/badges/24.jpg',
  },
  {
    milestone: '250 Miles',
    title: '1960’s Batman Level',
    description: 'Thunk! Holy blisters Batman, you knocked out 250 miles!',
    badge: '/badges/25.jpg',
  },
  {
    milestone: '260 Miles',
    title: 'Mr. T Level',
    description: '“I pity the fool who quits now!”',
    badge: '/badges/26.jpg',
  },
  {
    milestone: '270 Miles',
    title: 'Mars Level',
    description: 'Congrats on making it 270 miles! Only 70.489 million more miles and you would make it to Mars.',
    badge: '/badges/27.jpg',
  },
  {
    milestone: '280 Miles',
    title: 'Tranquility Level',
    description: 'Let the stress fall from your shoulders. You are lighter than air!',
    badge: '/badges/28.jpg',
  },
  {
    milestone: '290 Miles',
    title: 'Medal Master Level',
    description: 'Congrats from Trevor -host of the MTA Podcast and maniacal creator of virtual race medals! You are almost to 300.',
    badge: '/badges/29.jpg',
  },
  {
    milestone: '300 Miles',
    title: 'Scream Level',
    description: <>AAAAAAAH!! AAIEEEEEEE!!!! YEAAAAAAH!!! <br/><br/>CONGRATS ON REACHING MILE 300! PLEASE EXCUSE US WHILE WE OFFER YOU A CONGRATULATORY SCREAM!! 😱 😱 😱</>,
    badge: '/badges/30.jpg',
  },


  // ===
  // 300
  // ===

  {
    milestone: '310 Miles',
    title: 'Socrates Level',
    description: <>“The unexamined life is not worth living.” <br/><br/>Perhaps the unlogged run is not worth running?</>,
    badge: '/badges/31.jpg',
  },
  {
    milestone: '320 Miles',
    title: 'Lao Tzu Level',
    description: '“A journey of a thousand miles begins with a single step.”',
    badge: '/badges/32.jpg',
  },
  {
    milestone: '330 Miles',
    title: 'Joan of Arc Level',
    description: <>“I am not afraid... I was born to do this”. <br/><br/>(And you were born to complete this challenge!)</>,
    badge: '/badges/33.jpg',
  },
  {
    milestone: '340 Miles',
    title: 'Jimmy Hendrix Level',
    description: '“If I’m free, it’s because I’m always running”',
    badge: '/badges/34.jpg',
  },
  {
    milestone: '350 Miles',
    title: 'Marcus Aurelius Level',
    description: '“You have power over your mind - not outside events. Realize this, and you will find strength.”',
    badge: '/badges/35.jpg',
  },
  {
    milestone: '360 Miles',
    title: 'Yoda Level',
    description: '“Do or do not. There is no try.”',
    badge: '/badges/36.jpg',
  },
  {
    milestone: '370 Miles',
    title: 'Solomon The Wise Level',
    description: '“The race is not to the swift, nor the battle to the strong . . . but time and chance happens to them all.”',
    badge: '/badges/37.jpg',
  },
  {
    milestone: '380 Miles',
    title: 'René Descartes Level',
    description: 'Simple logic. I run, therefore I am (a runner).',
    badge: '/badges/38.jpg',
  },
  {
    milestone: '390 Miles',
    title: 'Gandalf Level',
    description: <>“All we have to decide is what to do with the time that is given us.”<br/><br/>(And now is the perfect time to run!)</>,
    badge: '/badges/39.jpg',
  },
  {
    milestone: '400 Miles',
    title: 'Cleopatra Level',
    description: 'Amazing! Nothing is stopping you now. Like Cleopatra said, “I will not be triumphed over.”',
    badge: '/badges/40.jpg',
  },
  {
    milestone: '410 Miles',
    title: 'Forest Gump Level',
    description: '“I just felt like running”',
    badge: '/badges/41.jpg',
  },
  {
    milestone: '420 Miles',
    title: 'Bonafide Hippy Level',
    description: 'Far out man! You made it to like 420 miles or something. 👏',
    badge: '/badges/42.jpg',
  },
  {
    milestone: '430 Miles',
    title: 'Rocky Balboa Level',
    description: <>Getting strong now <br/>Won't be long now <br/>Getting strong now <br/>Gonna fly now!</>,
    badge: '/badges/43.jpg',
  },
  {
    milestone: '440 Miles',
    title: 'Napoleon Level',
    description: 'Keep fighting, the medal will be totally worth it!',
    badge: '/badges/44.jpg',
  },
  {
    milestone: '450 Miles',
    title: 'Hangry Birds Level',
    description: 'Nice run! Now eat a snack before you get “hangry.”',
    badge: '/badges/45.jpg',
  },
  {
    milestone: '460 Miles',
    title: 'Bizarre Dolphin Level',
    description: 'Life is short so just cut loose and do your thing!',
    badge: '/badges/46.jpg',
  },
  {
    milestone: '470 Miles',
    title: 'Simple Furniture Level',
    description: 'Nice run! Remember to rest and recover. Van Gogh painted this room with simple furniture in 1888.',
    badge: '/badges/47.jpg',
  },
  {
    milestone: '480 Miles',
    title: 'Lit Skeleton Level',
    description: 'You made it past 480 miles, that’s so lit! 🔥 Van Gogh painted this skeleton with a burning cigarette in 1886.',
    badge: '/badges/48.jpg',
  },
  {
    milestone: '490 Miles',
    title: 'Starry Night Level',
    description: 'You are almost to 500 miles! You can Gogh the distance!',
    badge: '/badges/49.jpg',
  },
  {
    milestone: '500 Miles',
    title: 'Van Gogh Level',
    description: 'Congrats!!! We hear 👂 that you’ve run 500 miles. How did it gogh?',
    badge: '/badges/50.jpg',
  },


  // ===
  // 500
  // ===

  {
    milestone: '510 Miles',
    title: 'Camino de Santiago Level',
    description: <>Congrats on your journey! You’ve gone the total distance of the famous Camino de Santiago path in Spain - a pilgrim path that dates back to the 8th century.</>,
    badge: '/badges/51.jpg',
  },
  {
    milestone: '520 Miles',
    title: 'Windy Beach Run Level',
    description: <>Congrats on reaching the 520 mile mark! Keep going no matter what happens!</>,
    badge: '/badges/52.jpg',
    caption: <>“Where does the power come from, to see the race to its end? From within!”<br/><em>-From the movie Chariots of Fire</em></>,
  },
  {
    milestone: '530 Miles',
    title: 'Canoe Carry Level',
    description: <>It might be hard but you will get to the finish line!</>,
    badge: '/badges/53.jpg',
    caption: <>“My basic philosophy can be summed up by an expression we use in Norwegian: hurry slowly. Get there, but be patient.”<br/><em>-Greta Waitz, 9 time winner of the NYC Marathon</em></>
  },
  {
    milestone: '540 Miles',
    title: 'Magic Mustache Level',
    description: <>“To give anything less than your best is to sacrifice the gift.”<br/><em>-Steve Prefontaine</em></>,
    badge: '/badges/54.jpg',
  },
  {
    milestone: '550 Miles',
    title: 'Rocket Hat Level',
    description: <>“My philosophy on running is, I don't dwell on it, I do it.”</>,
    badge: '/badges/55.jpg',
    caption: <><em>Joan Benoit Samuelson winner of the first women’s Olympic Marathon</em></>
  },
  {
    milestone: '560 Miles',
    title: 'Anti-Gravity Boots Level',
    description: <>Look at you bounding through this virtual run challenge!<br/><em>Photo: Abebe Bikila, iconic Ethiopian Marathoner</em></>,
    badge: '/badges/56.jpg',
  },
  {
    milestone: '570 Miles',
    title: 'Ultra-Ultramarathon Man Level',
    description: <>“Run when you can, walk if you have to, crawl if you must; just never give up.”<br/><em>-Dean Karnazes</em></>,
    badge: '/badges/57.jpg',
  },
  {
    milestone: '580 Miles',
    title: 'Bear Escape Level',
    description: <>Wow! You’ve run this far and without being chased. Keep going and don’t look back now.</>,
    badge: '/badges/58.jpg',
  },
  {
    milestone: '590 Miles',
    title: 'Porta-Potty Blast Off Level',
    description: <>Nice run! Your escape pod is ready.</>,
    badge: '/badges/59.jpg',
  },
  {
    milestone: '600 Miles',
    title: 'No Limits Level',
    description: <>Who runs this far? Surely you are no mere mortal.</>,
    badge: '/badges/60.jpg',
    caption: <>“No human is limited.”<br/><em>-Eliud Kipchoge, first (and only) person to run a sub-two hour marathon.</em></>
  },
  {
    milestone: '610 Miles',
    title: 'Savage Betty White Level',
    description: 'The next time you feel tired remember Betty White is still kicking at age 99.',
    badge: '/badges/61.jpg',
  },
  {
    milestone: '620 Miles',
    title: 'Intense Unicorn Level',
    description: 'Some folks said we needed a unicorn level. CHALLENGE ACCEPTED',
    badge: '/badges/62.jpg',
  },
  {
    milestone: '630 Miles',
    title: 'Leprechaun Level',
    description: <>Celebrate your progress! Remember the harder you work the luckier you get.</>,
    badge: '/badges/63.jpg',
  },
  {
    milestone: '640 Miles',
    title: 'Shoe Addiction Level',
    description: 'Must . . . buy . . . more . . . running shoes . . . can’t restrain buying finger . . . click click click.',
    badge: '/badges/64.jpg',
  },
  {
    milestone: '650 Miles',
    title: 'Wig Level',
    description: <>Congrats on reaching the 650 mile mark! Andy Warhol’s hair salutes you.</>,
    badge: '/badges/65.jpg',
    caption: <><em>The pioneer of pop art was also famous for wearing wigs. Keep going for more Warhol classics.</em></>,
  },
  {
    milestone: '660 Miles',
    title: 'Marilyn Monroe Level',
    description: <>660 miles . . . fabulous, simply fabulous!</>,
    badge: '/badges/66.jpg',
    caption: <><em>Silkscreen painting created by Andy Warhol in 1962.</em></>,
  },
  {
    milestone: '670 Miles',
    title: 'Zebra Level',
    description: <>Keep galloping through this challenge!</>,
    badge: '/badges/67.jpg',
    caption: <><em>Silkscreen painting created by Andy Warhol in 1983 as part of the Endangered Species Series</em></>,
  },
  {
    milestone: '680 Miles',
    title: 'Muhammad Ali Level',
    description: <>Wow you made it to 680 miles! Be proud, remember what Ali said, “It's not bragging if you can back it up”.</>,
    badge: '/badges/68.jpg',
    caption: <><em>Photograph and screen print created by Andy Warhol in 1977</em></>,
  },
  {
    milestone: '690 Miles',
    title: 'Giant Panda Level',
    description: <>Nice run! Time for a nibble. You are so close to reaching 700 miles!!</>,
    badge: '/badges/69.jpg',
    caption: <><em>Silkscreen painting created by Andy Warhol in 1983 as part of the Endangered Species Series</em></>,
  },
  {
    milestone: '700 Miles',
    title: 'Can of Soup Level',
    description: <>CAN you believe you made it this far? Amazing!! Nothing CAN stop you when you CONCENTRATE on your goal! We have CONDENSED it down to this: You truly CAN do hard things!"</>,
    badge: '/badges/70.jpg',
  },


  // ===
  // 700
  // ===

  {
    milestone: '710 Miles',
    title: 'Pura Vida Level',
    description: <>Nice run! Now close your eyes and go to your happy place.</>,
    badge: '/badges/71.jpg',
  },
  {
    milestone: '720 Miles',
    title: 'Baby Sloth Level',
    description: <>Nice work! You have earned 1 hug from a baby sloth.</>,
    badge: '/badges/72.jpg',
  },
  {
    milestone: '730 Miles',
    title: 'Howler Monkey Level',
    description: <>When you run by all the monkeys take notice.</>,
    badge: '/badges/73.jpg',
  },
  {
    milestone: '740 Miles',
    title: 'Jaguar Level',
    description: <>Fantastic! You are one coooooool cat.</>,
    badge: '/badges/74.jpg',
  },
  {
    milestone: '750 Miles',
    title: 'Piña Colada Level',
    description: <>Nice work! Now that you’ve made it past 750 miles reach out and grab that piña colada.</>,
    badge: '/badges/75.jpg',
  },
  {
    milestone: '760 Miles',
    title: 'Psychedelic Frog Level',
    description: <>Pro tip: If you see a frog like this don’t lick it.</>,
    badge: '/badges/76.jpg',
  },
  {
    milestone: '770 Miles',
    title: 'Blowfish Level',
    description: <>In honor of your progress we taught this fish to play a jaunty tune.</>,
    badge: '/badges/77.jpg',
  },
  {
    milestone: '780 Miles',
    title: 'Toucan Level',
    description: <>Pro tip: Don’t try to do math while running.</>,
    badge: '/badges/78.jpg',
  },
  {
    milestone: '790 Miles',
    title: 'Volcano Level',
    description: <>You made it to 790 and now this run challenge is about to explode!</>,
    badge: '/badges/79.jpg',
  },
  {
    milestone: '800 Miles',
    title: 'Zip Line Level',
    description: <>Congrats on reaching 800! You are speeding toward 1,000 miles.</>,
    badge: '/badges/80.jpg',
  },
  {
    milestone: '810 Miles',
    title: 'Hill Dominator Level',
    description: <>Don’t dread the hills. (Hills are secret strength work).</>,
    badge: '/badges/81.jpg',
  },
  {
    milestone: '820 Miles',
    title: 'Autobahn Level',
    description: <>Wunderbar! You have gone over 1315 kilometers, the equivalent of driving from Hamburg to Stuttgart and back. Vroom vroom!</>,
    badge: '/badges/82.jpg',
  },
  {
    milestone: '830 Miles',
    title: 'Funny Marathon Sign Level',
    description: <>Keep giving your all!</>,
    badge: '/badges/83.jpg',
  },
  {
    milestone: '840 Miles',
    title: 'General Gluteus Maximus Buttimus Cheekimus Level',
    description: <>“Are you not entertained?”</>,
    badge: '/badges/84.jpg',
  },
  {
    milestone: '850 Miles',
    title: 'Caravan Level',
    description: <>Follow the herd and run to the oasis.</>,
    badge: '/badges/85.jpg',
  },
  {
    milestone: '860 Miles',
    title: 'Appalachian Trail Level',
    description: <>In Appalachian Trail miles you’ve gone from Springer Mountain, Georgia, (the beginning point) to Shenandoah National Park in Virginia. And you never had to sleep outside!</>,
    badge: '/badges/86.jpg',
  },
  {
    milestone: '870 Miles',
    title: 'Traditional Runner Level',
    description: <>Massive respect to all the traditional/tribal runners around the world, like the <strong>Tarahumara</strong> of Mexico, who run incredible distances wearing huaraches like these.</>,
    badge: '/badges/87.jpg',
  },
  {
    milestone: '880 Miles',
    title: 'Shakespeare Level',
    description: <>To run or not to run, that is never the question.</>,
    badge: '/badges/88.jpg',
  },
  {
    milestone: '890 Miles',
    title: 'American Gothic Goths Level',
    description: <>The world looks different after you’ve run 890 miles.</>,
    badge: '/badges/89.jpg',
  },
  {
    milestone: '900 Miles',
    title: 'Raphael’s Angels Level',
    description: <>100 miles to go! We are rooting for you! 🙌</>,
    badge: '/badges/90.jpg',
  },
  {
    milestone: '910 Miles',
    title: 'Frida Kahlo Level',
    description: <>“Feet, what do I need you for when I have wings to fly?”<br/><em>-Frida Kahlo, Mexican Painter</em></>,
    badge: '/badges/91.jpg',
  },
  {
    milestone: '920 Miles',
    title: 'Hokusai Level',
    description: <>Life is a great adventure. You’ll be surprised where running can take you.</>,
    badge: '/badges/92.jpg',
    caption: <><em>The Great Wave off Kanagawa -a woodblock print by the Japanese artist Hokusai. (Published between 1829 and 1833)</em></>,
  },
  {
    milestone: '930 Miles',
    title: 'Salvador Dalí Level',
    description: <>Progress not perfection. Look how far you’ve come! Running turns back the clock and adds days to your life.</>,
    badge: '/badges/93.jpg',
    caption: <em>“The Persistence of Memory by Spanish painter Salvador Dalí (1931)”</em>,
  },
  {
    milestone: '940 Miles',
    title: 'Very Impressed Michelangelo Level',
    description: <>You made it this far. Michelangelo would be very impressed. Keep going to see famous works by the Renaissance master.</>,
    badge: '/badges/94.jpg',
  },
  {
    milestone: '950 Miles',
    title: 'Bacchus Level',
    description: <>To assist you on your quest we offer you this chalice of UCAN energy bars.</>,
    badge: '/badges/95.jpg',
    caption: <em>Bacchus is a marble sculpture by the Italian High Renaissance sculptor, painter, architect and poet Michelangelo created between 1496–1497.</em>,
  },
  {
    milestone: '960 Miles',
    title: 'Pietà Level',
    description: <>Wow, 960 miles! You must be exhausted. Keep going you’re almost there!</>,
    badge: '/badges/96.jpg',
    caption: <em>The Pietà was created by Michelangelo between 1498–1499 and its housed in St. Peter's Basilica. It is the only piece he ever signed.</em>,
  },
  {
    milestone: '970 Miles',
    title: 'Madonna Level',
    description: <>You don’t sweat you glisten.</>,
    badge: '/badges/97.jpg',
    caption: <em>The Madonna of Bruges is a marble sculpture by Michelangelo carved between 1501–1504</em>,
  },
  {
    milestone: '980 Miles',
    title: 'Swoll Moses Level',
    description: <>Just think of all the strength you are gaining through this challenge! To all those stuck in quarantine we say “let my people go” for a run.</>,
    badge: '/badges/98.jpg',
    caption: <em>Sculpted from marble by Michelangelo between 1513–1515</em>,
  },
  {
    milestone: '990 Miles',
    title: 'Statue of David Level',
    description: <>Almost to 1000 miles! You are a colossus . . . a true specimen of human potential!<br/><br/></>,
    badge: '/badges/99.jpg',
    caption: <em>The David was sculpted in marble by Michelangelo between 1501 and 1504 and stands 17 feet (5.17-meters) high.</em>,
  },
  {
    milestone: '1000 Miles',
    title: 'Creation of Running Level',
    description: <>Hallelujah! Let all the angels sing&hellip; you have reached 1,000 miles. What an epic journey! Running is a gift and we hope that the miles have brought you joy.<br/><br/><em>Painted in 1512 on the ceiling of the Sistine Chapel in Rome, Michelangelo’s Creation of Adam is one of the most replicated paintings of all time.</em></>,
    badge: '/badges/100.jpg',
  },


  // ====
  // 1000
  // ====

  {
    milestone: '1010 Miles',
    title: 'Fitness Fanatic Level',
    description: 'You are heating up! Keep going to see how tough you really are. 😉',
    badge: '/badges/101.jpg',
  },
  {
    milestone: '1020 Miles',
    title: 'Burpee Level',
    description: 'Great work! Now give me 30 burpees.',
    badge: '/badges/102-b.jpg',
  },
  {
    milestone: '1030 Miles',
    title: 'Burping Level',
    description: 'Nice work! Now let out a satisfying burp.',
    badge: '/badges/103-b.jpg',
  },
  {
    milestone: '1040 Miles',
    title: 'Pushup Level',
    description: 'For extra fun do 20 push ups.',
    badge: '/badges/104-b.jpg',
  },
  {
    milestone: '1050 Miles',
    title: 'Pushup Pop Level',
    description: 'Now eat 20 of these (just kidding).',
    badge: '/badges/105-b.jpg',
  },
  {
    milestone: '1060 Miles',
    title: 'Crunch Level',
    description: 'You made it to mile 1060! Now follow it up with 25 crunches.',
    badge: '/badges/106.jpg',
  },
  {
    milestone: '1070 Miles',
    title: 'Crunch Bar Level',
    description: 'This might help with the motivation.',
    badge: '/badges/107.jpg',
  },
  {
    milestone: '1080 Miles',
    title: 'Plank Level',
    description: 'Now hold a plank for 1 minute. Make sure to keep your back straight.',
    badge: '/badges/108.jpg',
  },
  {
    milestone: '1090 Miles',
    title: 'Planking Level',
    caption: 'Popular in 2008, Planking was a fad consisting of posting a photo of yourself lying face down in an unusual or incongruous location.',
    badge: '/badges/109.jpg',
  },
  {
    milestone: '1100 Miles',
    title: 'Calf Raises Level',
    description: 'You’re not done yet! Do 20 calf raises (with or without weights).',
    badge: '/badges/110.jpg',
  },
  {
    milestone: '1110 Miles',
    title: 'Raise a Calf Level',
    description: 'You’ve done calf raises now try raising a calf.',
    badge: '/badges/111.jpg',
  },
  {
    milestone: '1120 Miles',
    title: 'Chin Up Level',
    description: 'Do five chin ups (or hang on the bar as long as you can).',
    badge: '/badges/112.jpg',
  },
  {
    milestone: '1130 Miles',
    title: '“Keep Your Chin Up” Level',
    description: 'Everything will be fine.',
    badge: '/badges/113.jpg',
  },
  {
    milestone: '1140 Miles',
    title: 'Bicycle Kicks Level',
    description: 'Lay on your back and do 40 bicycle kicks (20 per leg).',
    badge: '/badges/114.jpg',
  },
  {
    milestone: '1150 Miles',
    title: 'Kicking a Bicycle Level',
    description: 'Now for more fun, try kicking a bicycle.',
    badge: '/badges/115-b.jpg',
  },
  {
    milestone: '1160 Miles',
    title: 'Glute Bridge Level',
    description: 'For a great core workout hold bridge for 60 seconds.',
    badge: '/badges/116.jpg',
  },
  {
    milestone: '1170 Miles',
    title: 'Gluten Bridge Level',
    description: 'You are on a roll! Go against the grain and don’t let this challenge get stale.',
    badge: '/badges/117.jpg',
  },
  {
    milestone: '1180 Miles',
    title: 'Lunge Level',
    description: 'Now do 20 lunges (10 on each side).',
    badge: '/badges/118.jpg',
  },
  {
    milestone: '1190 Miles',
    title: 'Lounge Level',
    badge: '/badges/119.jpg',
  },
  {
    milestone: '1200 Miles',
    title: 'Running Down a Dream Level',
    description: '"Working on a mystery, going where ever it leads.”',
    badge: '/badges/120.jpg',
  },
  {
    milestone: '1210 Miles',
    title: 'Ready to Run Level',
    description: <>“Ready ready ready ready ready to run<br/>All I’m ready to do is have some fun”</>,
    badge: '/badges/121.jpg',
  },
  {
    milestone: '1220 Miles',
    title: 'Running with the Devil Level',
    description: '“Yes I\'m livin\' at a pace that kills” 😈',
    badge: '/badges/122.jpg',
  },
  {
    milestone: '1230 Miles',
    title: 'Born to Run Level',
    description: '“Baby we were born to run”',
    badge: '/badges/123.jpg',
  },
  {
    milestone: '1240 Miles',
    title: 'Run the World (Girls) Level',
    description: <>“Endless power<br />With our love we can devour!”</>,
    badge: '/badges/124.jpg',
  },
  {
    milestone: '1250 Miles',
    title: 'Take the Money and Run Level',
    description: '“Hoo-hoo-hoo, go on, take the money and run” 🤑',
    badge: '/badges/125.jpg',
  },
  {
    milestone: '1260 Miles',
    title: 'Run to the Hills Level',
    description: '“Run to the hills run for your lives!” 🤘',
    badge: '/badges/126.jpg',
  },
  {
    milestone: '1270 Miles',
    title: 'Running on Empty Level',
    description: <>"Running on (running on empty)<br/>Running on (running blind)<br/>Running on (running into the sun)<br/>But I'm running behind"</>,
    badge: '/badges/127.jpg',
  },
  {
    milestone: '1280 Miles',
    title: 'Run Run Rudolph Level',
    description: '“Run, run Rudolph, a running like a son-of-a-gun!”',
    badge: '/badges/128.jpg',
  },
  {
    milestone: '1290 Miles',
    title: 'My Little Run Away Level',
    description: '“And I wonder, I wa wa wa wa wonder…”',
    badge: '/badges/129.jpg',
  },
  {
    milestone: '1300 Miles',
    title: 'Run Through the Jungle Level',
    description: '“Better run through the jungle”',
    badge: '/badges/130.jpg',
  },
  {
    milestone: '1310 Miles',
    title: 'Run to You Level',
    description: <>“Cause when the feelin's right I'm gonna run all night<br/>I'm gonna run to you!”</>,
    badge: '/badges/131.jpg',
  },
  {
    milestone: '1320 Miles',
    title: 'I Ran (So Far Away) Level',
    description: <>“And I ran, I ran so far away<br/>I just ran, I ran all night and day<br/>I couldn't get away”</>,
    badge: '/badges/132.jpg',
  },
  {
    milestone: '1330 Miles',
    title: 'Run Like Hell Level',
    description: <>“Run, run, run, run, run, run, run, run<br/>Run, run, run, run, run, run, run, run”</>,
    badge: '/badges/133.jpg',
  },
  {
    milestone: '1340 Miles',
    title: 'We Run the Night Level',
    description: <>“Taking me so high up, up, up<br/>A place that I've never been, ahhhh!”</>,
    badge: '/badges/134.jpg',
  },
  {
    milestone: '1350 Miles',
    title: 'Take it on the Run Level',
    description: <>Heard it from a friend who<br/>Heard it from a friend who<br/>Heard it from another you been messin' around<br/>(with a 1500 mile challenge)</>,
    badge: '/badges/135.jpg',
  },
  {
    milestone: '1360 Miles',
    title: 'Run DMC Level',
    badge: '/badges/136.jpg',
  },
  {
    milestone: '1370 Miles',
    title: 'Get Schwifty Level',
    description: <>Oh, yeah!<br/>You gotta get schwifty.<br/>You gotta get schwifty in here.</>,
    badge: '/badges/137.jpg',
  },
  {
    milestone: '1380 Miles',
    title: 'Rise Above Level',
    description: 'You are strong. You are confident. You are a success!',
    badge: '/badges/138.jpg',
  },
  {
    milestone: '1390 Miles',
    title: 'Chainsaw Mickey Level',
    badge: '/badges/139.jpg',
  },
  {
    milestone: '1400 Miles',
    title: 'Selfie Level',
    description: 'Congrats on reaching 1,400 miles! Time to take a selfie.',
    badge: '/badges/140.jpg',
  },
  {
    milestone: '1410 Miles',
    title: 'Release the Kraken Level',
    description: 'It takes a special kind of determination to have made it this far.',
    badge: '/badges/141.jpg',
  },
  {
    milestone: '1420 Miles',
    title: 'Breaking Good Level',
    description: 'You are getting better and better!',
    badge: '/badges/142.jpg',
  },
  {
    milestone: '1430 Miles',
    title: 'Across Europe Level',
    description: 'Wow! As the crow flies, you have gone from Kiev, Ukraine, all the way to Bristol, UK.',
    badge: '/badges/143.jpg',
  },
  {
    milestone: '1440 Miles',
    title: 'The Girl with the Pearl Tongue Ring Level',
    caption: 'The original Girl with the Pearl Earring was painted by the Dutch artist Johannes Vermeer in 1665.',
    badge: '/badges/144.jpg',
  },
  {
    milestone: '1450 Miles',
    title: 'Whistling Whistler’s Mother Level',
    caption: 'Whenever there was horseplay Whistler’s mother had her whistle ready.',
    badge: '/badges/145.jpg',
  },
  {
    milestone: '1460 Miles',
    title: 'Nervous Picasso Level',
    description: 'Don’t be nervous you are almost there!',
    badge: '/badges/146.jpg',
  },
  {
    milestone: '1470 Miles',
    title: 'Never-ending Stairs Level',
    description: 'You will reach your goal. Keep going!',
    badge: '/badges/147.jpg',
  },
  {
    milestone: '1480 Miles',
    title: 'Powerful Eyebrow Level',
    caption: <><em>Self-Portrait with Thorn Necklace and Hummingbird</em> was painted in 1940 by Frida Kahlo.</>,
    badge: '/badges/148.jpg',
  },
  {
    milestone: '1490 Miles',
    title: 'I Love Running Level',
    caption: <>Taken from <em>The Two Fridas</em>, a 1939 oil painting by Frida Kahlo</>,
    badge: '/badges/149.jpg',
  },
  {
    milestone: '1500 Miles',
    title: '1500 Frida Sugar Skull Level',
    description: 'In spectacular fashion you have reached 1500 miles -the end of the run tracker! Not many people have made it this far. Salud!',
    badge: '/badges/150.jpg',
  },


  // ====
  // 1500
  // ====

  {
    milestone: '1510 Miles',
    title: 'Meme Level',
    description: 'You made it to the meme levels of the run tracker. Keep going for more fun.',
    badge: '/badges/151.jpg',
  },
  {
    milestone: '1520 Miles',
    title: 'Most Interesting Man Level',
    badge: '/badges/152.jpg',
  },
  {
    milestone: '1530 Miles',
    title: 'Pizza Greeting Card Level',
    badge: '/badges/153.jpg',
    width: 350,
  },
  {
    milestone: '1540 Miles',
    title: 'Lady Yelling at Cat Level',
    badge: '/badges/154.jpg',
    width: 400,
  },
  {
    milestone: '1550 Miles',
    title: 'Baby Yoda Level',
    badge: '/badges/155.jpg',
  },
  {
    milestone: '1560 Miles',
    title: 'Morpheus Level',
    badge: '/badges/156.jpg',
  },
  {
    milestone: '1570 Miles',
    title: 'Batman Slapping Robin Level',
    badge: '/badges/157.jpg',
  },
  {
    milestone: '1580 Miles',
    title: 'Drake Level',
    badge: '/badges/158.jpg',
  },
  {
    milestone: '1590 Miles',
    title: 'Tuba on Head Level',
    badge: '/badges/159.jpg',
    width: 350,
  },
  {
    milestone: '1600 Miles',
    title: 'Chuck Norris Level',
    badge: '/badges/160.jpg',
    width: 350,
  },
  {
    milestone: '1610 Miles',
    title: 'The Great Crave Level',
    description: 'Now me eat cookies!',
    badge: '/badges/161.jpg',
  },
  {
    milestone: '1620 Miles',
    title: 'Find Wind, Clear Morning Level',
    caption: 'Known as Red Fuji, a woodblock print by Japanese artist Hokusai created 1832',
    badge: '/badges/162.jpg',
  },
  {
    milestone: '1630 Miles',
    title: 'Super Food Level',
    badge: '/badges/163.jpg',
  },
  {
    milestone: '1640 Miles',
    title: 'Mullet Level',
    description: 'Mullets are like marathons: business in the front and party in the back.',
    badge: '/badges/164.jpg',
  },
  {
    milestone: '1650 Miles',
    title: 'The Great Waiver Level',
    description: 'You assume all liabilities for how this goes.',
    badge: '/badges/165.jpg',
  },
  {
    milestone: '1660 Miles',
    title: 'Suspended Bridge Level',
    caption: 'Created by Hokusai between 1820–1840',
    badge: '/badges/166.jpg',
  },
  {
    milestone: '1670 Miles',
    title: 'The Running Dead Level',
    description: 'Like ‘The Walking Dead’ only faster',
    badge: '/badges/167.jpg',
  },
  {
    milestone: '1680 Miles',
    title: 'Galactic Treadmill Level',
    description: 'Somewhere in the multiverse this is a thing',
    badge: '/badges/168.jpg',
  },
  {
    milestone: '1690 Miles',
    title: 'Coach Nicole Level',
    badge: '/badges/169.jpg',
  },
  {
    milestone: '1700 Miles',
    title: 'That’s A Long Way! Level',
    description: 'You walked from Cairo, Egypt, to Tunis the capital of Tunisia. That’s a long way!',
    badge: '/badges/170.jpg',
    width: 350,
  },
  {
    milestone: '1710 Miles',
    title: 'Doing the Wave Level',
    description: 'Just think. This is the most exercise some people get',
    badge: '/badges/171.jpg',
    width: 350,
  },
  {
    milestone: '1720 Miles',
    title: 'Cherry-Blossom Level',
    caption: 'Painted by Hokusai between 1830-1834',
    badge: '/badges/172.jpg',
  },
  {
    milestone: '1730 Miles',
    title: 'Running App Level',
    description: 'Soon there will be an app that runs your miles for you.',
    badge: '/badges/173.jpg',
  },
  {
    milestone: '1740 Miles',
    title: 'Walking on Legos Level',
    badge: '/badges/174.jpg',
    width: 350,
  },
  {
    milestone: '1750 Miles',
    title: 'Bed of Donuts Level',
    description: 'Go to your happy place.',
    badge: '/badges/175.jpg',
    width: 350,
  },
  {
    milestone: '1760 Miles',
    title: 'Light Saber Level',
    description: 'If you are ever handed a light saber don’t point it at your face!',
    badge: '/badges/176.jpg',
    width: 350,
  },
  {
    milestone: '1770 Miles',
    title: 'MTA Podcast Level',
    badge: '/badges/177.jpg',
  },
  {
    milestone: '1780 Miles',
    title: 'Charging Hippo Level',
    description: 'Believe it or not, hippos can run 30 mph.',
    badge: '/badges/178.jpg',
    width: 350,
  },
  {
    milestone: '1790 Miles',
    title: 'Lemur Level',
    badge: '/badges/179.jpg',
  },
  {
    milestone: '1800 Miles',
    title: 'Flying High Level',
    caption: 'Painting by M.C. Escher (1926)',
    badge: '/badges/180.jpg',
    width: 350,
  },
  {
    milestone: '1810 Miles',
    title: 'The Great Britain Wave Level',
    description: 'Good show! Your progress is duly acknowledged.',
    badge: '/badges/181.jpg',
    width: 350,
  },
  {
    milestone: '1820 Miles',
    title: 'Dragon Level',
    caption: 'Matsuri Yatai Dragon, painting on the ceiling by Katsushika Hokusai',
    badge: '/badges/182.jpg',
  },
  {
    milestone: '1830 Miles',
    title: 'Power Lifting Level',
    badge: '/badges/183.jpg',
  },
  {
    milestone: '1840 Miles',
    title: 'Family Fitness Level',
    badge: '/badges/184.jpg',
  },
  {
    milestone: '1850 Miles',
    title: 'Exercise Bike Level',
    description: 'Don’t forget to wear your exercise bike riding necktie!',
    badge: '/badges/185.jpg',
  },
  {
    milestone: '1860 Miles',
    title: 'Whatever This Thing Does Level',
    badge: '/badges/186.jpg',
  },
  {
    milestone: '1870 Miles',
    title: 'Wooden Treadmill Level',
    badge: '/badges/187.jpg',
  },
  {
    milestone: '1880 Miles',
    title: 'Horse-Simulation Machine Level',
    badge: '/badges/188.jpg',
  },
  {
    milestone: '1890 Miles',
    title: 'Victorian Ab Machine Level',
    badge: '/badges/189.jpg',
  },
  {
    milestone: '1900 Miles',
    title: 'Vibrating Belt Level',
    badge: '/badges/190.jpg',
    width: 350,
  },
  {
    milestone: '1910 Miles',
    title: 'The Great Wave Off Conan-agawa Level',
    description: 'Far out!',
    badge: '/badges/191.jpg',
  },
  {
    milestone: '1920 Miles',
    title: '1920’s Level',
    description: 'You are the bee’s knees!',
    badge: '/badges/192.jpg',
  },
  {
    milestone: '1930 Miles',
    title: '1930s Level',
    description: 'Run strong to the finish line of your 2,000 mile challenge!',
    caption: '(Photo: Jesse Owens at the 1936 Olympics)',
    badge: '/badges/193.jpg',
    width: 350,
  },
  {
    milestone: '1940 Miles',
    title: '1940s Level',
    badge: '/badges/194.jpg',
  },
  {
    milestone: '1950 Miles',
    title: '1950s Level',
    caption: 'Yeah baby! 50 more miles to go!',
    badge: '/badges/195.jpg',
  },
  {
    milestone: '1960 Miles',
    title: '1960s Level',
    description: '“The answer, my friend, is blowin’ in the wind.”',
    badge: '/badges/196.jpg',
    width: 350,
  },
  {
    milestone: '1970 Miles',
    title: '1970s Level',
    description: 'Only 30 miles to go!',
    badge: '/badges/197.jpg',
  },
  {
    milestone: '1980 Miles',
    title: '1980s Level',
    caption: '20 more miles you sexy beast!',
    badge: '/badges/198.jpg',
    width: 350,
  },
  {
    milestone: '1990 Miles',
    title: '1990s Level',
    caption: 'Stop. Hammer time! (Hammer those final miles!)',
    badge: '/badges/199.jpg',
  },
  {
    milestone: '2000 Miles',
    title: 'The Great Wave Level',
    description: 'Yatta! You made it. What an incredible journey of self-discipline and perseverance! Respect. 🙏',
    badge: '/badges/200.jpg',
  },


  // ====
  // 2000
  // ====

  {
    milestone: '2010 Miles',
    title: 'Tony Schollum Level',
    badge: '/badges/201.jpg',
  },
  {
    milestone: '2020 Miles',
    title: 'Social Distancing Run Level',
    description: 'This is the virtual race that started it all!',
    badge: '/badges/202.jpg',
  },
  {
    milestone: '2030 Miles',
    title: 'Polar Plunge Level',
    badge: '/badges/203.jpg',
  },
  {
    milestone: '2040 Miles',
    title: 'Stay Hard Level',
    description: '"Don\'t stop when you\'re tired. Stop when you\'re done.” -David Goggins',
    badge: '/badges/204.jpg',
  },
  {
    milestone: '2050 Miles',
    title: 'Fire Walk Level',
    description: '“The path to success is to take massive, determined action.”  -Tony Robbins',
    badge: '/badges/205.jpg',
  },
  {
    milestone: '2060 Miles',
    title: 'Jumping Hurdles Level',
    badge: '/badges/206.jpg',
  },
  {
    milestone: '2070 Miles',
    title: 'Breakthrough Level',
    description: 'Wow, you just busted through 2070 total miles!',
    badge: '/badges/207.jpg',
  },
  {
    milestone: '2080 Miles',
    title: 'Dodging Bull Crap Level',
    badge: '/badges/208.jpg',
  },
  {
    milestone: '2090 Miles',
    title: '90 T-Shirts Level',
    description: 'David Eliuk from Canada holds the record for most shirts worn during a half marathon.',
    badge: '/badges/209.jpg',
  },
  {
    milestone: '2100 Miles',
    title: 'Smoking Fast Level',
    description: 'Fun fact! A Chinese runner who goes by Uncle Chen ran a 3:28 marathon while chain-smoking a pack of cigarettes',
    badge: '/badges/210.jpg',
  },
  {
    milestone: '2110 Miles',
    title: 'Dryer Carry Level',
    description: 'Ben Blowes completed the London Marathon with a tumble dryer strapped to his back.',
    badge: '/badges/211.jpg',
  },
  {
    milestone: '2120 Miles',
    title: 'Ski Boot Level',
    description: 'The fastest marathon in ski boots is 5 hours and 30 minutes',
    badge: '/badges/212.jpg',
  },
  {
    milestone: '2130 Miles',
    title: 'Quintuplet Level',
    description: 'This Idaho dad ran a half marathon in 2:19 while pushing quintuplets',
    badge: '/badges/213.jpg',
  },
  {
    milestone: '2140 Miles',
    title: 'Swim Fin Level',
    description: 'The fastest mile run in swim fins is 7:56',
    badge: '/badges/214.jpg',
  },
  {
    milestone: '2150 Miles',
    title: 'All Fours Level',
    description: 'The fastest 100 meters while running on all fours is 15.66 seconds.',
    badge: '/badges/215.jpg',
  },
  {
    milestone: '2160 Miles',
    title: 'Humphrey The Camel Level',
    description: 'This strange camel finished the London Marathon',
    badge: '/badges/216.jpg',
  },
  {
    milestone: '2170 Miles',
    title: 'Spider Woman Level',
    description: 'The fastest marathon dressed as a superhero (female) is 2:48:51 by Camille Herron',
    badge: '/badges/217.jpg',
  },
  {
    milestone: '2180 Miles',
    title: 'High Heels Level',
    description: '“It was painful”, said woman who ran in heels.',
    badge: '/badges/218.jpg',
  },
  {
    milestone: '2190 Miles',
    title: 'Cold Feet Level',
    description: 'The largest barefoot race on ice consisted of 361 participants in the Netherlands',
    badge: '/badges/219.jpg',
  },
  {
    milestone: '2200 Miles',
    title: 'Gas Mask Level',
    description: 'The fasted marathon wearing a gas mask is 3:54:55, in case you’re wondering',
    badge: '/badges/220.jpg',
  },
  {
    milestone: '2210 Miles',
    title: 'Panache Level',
    description: <>It takes a certain <em>flamboyant confidence</em> to go 2,210 miles.</>,
    badge: '/badges/221.jpg',
  },
  {
    milestone: '2220 Miles',
    title: 'Chutzpah Level',
    description: <>The <em>extreme self-confidence or audacity</em> it takes to reach this level is extraordinary.</>,
    badge: '/badges/222.jpg',
  },
  {
    milestone: '2230 Miles',
    title: 'Spizzerinctum Level',
    description: <>We believe you have the <em>guts, nerve, and backbone</em> to make it all the way to the end</>,
    badge: '/badges/223.jpg',
  },
  {
    milestone: '2240 Miles',
    title: 'Moxie Level',
    description: <>We admire your <em>energy, pep, and determination</em>.</>,
    badge: '/badges/224-b.jpg',
  },
  {
    milestone: '2250 Miles',
    title: 'Brainy Brain Level',
    description: 'Did you know the brain is the only organ that named itself?',
    badge: '/badges/225.jpg',
  },
  {
    milestone: '2260 Miles',
    title: 'Meditation Level',
    description: 'You are not your thoughts, you are the one observing your thoughts.',
    badge: '/badges/226.jpg',
  },
  {
    milestone: '2270 Miles',
    title: 'Law of Attraction Level',
    description: 'When you want something, all the universe conspires in helping you to achieve it.”  -Paulo Coelho',
    badge: '/badges/227.jpg',
  },
  {
    milestone: '2280 Miles',
    title: 'Face Book Level',
    description: 'There is no better use of your time than Face Book',
    badge: '/badges/228.jpg',
  },
  {
    milestone: '2290 Miles',
    title: 'Rodney Dangerfield Level',
    description: '“My wife and I were happy for 20 years. Then we met.”',
    badge: '/badges/229.jpg',
  },
  {
    milestone: '2300 Miles',
    title: 'Dictator Level',
    badge: '/badges/230.jpg',
  },
  {
    milestone: '2310 Miles',
    title: 'Godfather Level',
    badge: '/badges/231.jpg',
  },
  {
    milestone: '2320 Miles',
    title: 'Comrade Level',
    badge: '/badges/232.jpg',
  },
  {
    milestone: '2330 Miles',
    title: 'Gengis Khan Level',
    description: 'Gengis Khan also conquered 2330 miles',
    badge: '/badges/233.jpg',
  },
  {
    milestone: '2340 Miles',
    title: 'The Chairman Level',
    description: 'Get out of the chair man!',
    badge: '/badges/234.jpg',
  },
  {
    milestone: '2350 Miles',
    title: 'Ivar the Terrible Comedian Level',
    badge: '/badges/235.jpg',
  },
  {
    milestone: '2360 Miles',
    title: 'Bloody Marys Level',
    caption: 'These are the two bloody Marys',
    badge: '/badges/236.jpg',
  },
  {
    milestone: '2370 Miles',
    title: 'Vlad the Impaler Level',
    description: 'Vlad was the real life Count Dracula',
    badge: '/badges/237.jpg',
  },
  {
    milestone: '2380 Miles',
    title: 'Vlad the Inhaler Level',
    description: 'You can find anything, on the internet. I found this on my first try.',
    badge: '/badges/238.jpg',
  },
  {
    milestone: '2390 Miles',
    title: 'Narrow Escape Level',
    description: 'Wow! Look at all the miles you’ve gone without getting injured!',
    badge: '/badges/239.jpg',
  },
  {
    milestone: '2400 Miles',
    title: 'New Horizons Level',
    description: 'Only 100 miles to go until 2500!',
    badge: '/badges/240.jpg',
  },
  {
    milestone: '2410 Miles',
    title: 'Alaska Level',
    description: 'By reaching 2410 miles you have gone all the way across Alaska from east to west (without the mosquito bites and bear attacks).',
    badge: '/badges/241.jpg',
  },
  {
    milestone: '2420 Miles',
    title: 'M.C. Escher Level',
    description: 'Maurits Cornelis Escher (1898-1972) was a Dutch graphic artist who made mathematically inspired designs.',
    badge: '/badges/242.jpg',
  },
  {
    milestone: '2430 Miles',
    title: 'Reflecting Sphere Level',
    description: 'M.C. Escher 1935',
    badge: '/badges/243.jpg',
  },
  {
    milestone: '2440 Miles',
    title: 'Tessellation Level',
    description: 'Tessellation = an arrangement of shapes closely fitted together in a repeated pattern without gaps.',
    badge: '/badges/244.jpg',
  },
  {
    milestone: '2450 Miles',
    title: 'Sky and Water Level',
    description: '(M.C. Escher, June 1938, woodcut)',
    badge: '/badges/245.jpg',
  },
  {
    milestone: '2460 Miles',
    title: 'Eye Level',
    description: 'You must have scary stone-cold determination to finish this challenge. (M.C. Escher 1946)',
    badge: '/badges/246.jpg',
  },
  {
    milestone: '2470 Miles',
    title: 'Stars Level',
    description: 'You are so close you can taste it! (M.C. Escher wood cut 1948)',
    badge: '/badges/247.jpg',
  },
  {
    milestone: '2480 Miles',
    title: 'Ascending and Descending Level',
    description: 'Sometimes you go up, sometimes you go down. Keep going. (M.C. Escher 1953)',
    badge: '/badges/248.jpg',
  },
  {
    milestone: '2490 Miles',
    title: 'Bond of Union Level',
    description: 'Years have passed and you are now mentally fused with this run tracker. (M.C. Escher, 1956)',
    badge: '/badges/249.jpg',
  },
  {
    milestone: '2500 Miles',
    title: 'Endorphin Level',
    description: 'Soaring high! You made it all the way up to 2,500 miles on this run tracker! You have reached The Endorphin Level.',
    badge: '/badges/250.jpg',
  },


];
