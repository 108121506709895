import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';



export function IfAuthed ({ children, redirectTo = '/' }) {

  const user = useSelector((store) => store.authedUser);

  return user ? children : <Redirect to={redirectTo} />;

}


export function IfNotAuthed ({ children, redirectTo = '/profile'}) {

  const user = useSelector((store) => store.authedUser);

  return user ? <Redirect to={redirectTo} /> : children;

}
