import React, { useState } from 'react';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { isNumeric } from 'validator';
import { useDispatch } from 'react-redux';

import { addRun as addRunAPI, editRun as editRunAPI } from '../api/runs';
import { addRun, editRun, handleDeleteRun } from '../actions/runs';
import logger from '../lib/logger';



export default function RunForm ({onDone, run}) {

  const dispatch = useDispatch();

  const [date, setDate] = useState(run ? new Date(run.date) : Date.now());
  const [dateError, setDateError] = useState(false);
  const [distance, setDistance] = useState(run ? run.distance : '');
  const [distanceError, setDistanceError] = useState(false);
  const [unit, setUnit] = useState(run ? run.unit : 'mi');
  const [notes, setNotes] = useState(run ? run.notes : '');
  const _id = run ? run._id : null;;

  const handleSubmit = (e) => {
    e.preventDefault();

    const hasDateError = !date;
    const hasDinstanceError = !distance || !isNumeric(distance + '');
    setDateError(hasDateError);
    setDistanceError(hasDinstanceError);
    if (hasDateError || hasDinstanceError) {
      return;
    }

    const method = _id ? editRunAPI : addRunAPI;

    method({_id, date, distance, unit, notes})
      .then((response) => {
        switch (response.status) {
          case 'validationError':
            setDateError(!!response.errors.date);
            setDistanceError(!!response.errors.distance)
            break;
          case 'success':
            if (_id) {
              dispatch(editRun(response.run));
            } else {
              dispatch(addRun(response.run));
            }
            setDistance('');
            setNotes('');
            if (onDone) onDone();
            break;
          default:
            console.log(response);
            logger.push({error: 'error on run form', response});
            alert('An error occured');
        }
      })
      .catch((err) => {
        console.log(err);
        logger.push({err});
        alert('An error occured');
      })
    ;
  }

  const handleDelete = () => {
    dispatch(handleDeleteRun(_id));
    if (onDone) onDone();
  }

  return (
    <Form
      onSubmit={handleSubmit}
      noValidate
      className='run-form'
    >
      <Row>
        <Col xs={12} sm={6}>
          <Form.Group controlId='date'>
            <Form.Label>Date of run</Form.Label>
            <DatePicker
              id='date'
              className={'form-control' + (dateError ? ' is-invalid' : '')}
              wrapperClassName={dateError && ' is-invalid'}
              required
              selected={date}
              onChange={(date) => setDate(date)}
              maxDate={new Date()}
            />
            {dateError &&
              <Form.Control.Feedback type='invalid'>
                Date is required.
              </Form.Control.Feedback>
            }
          </Form.Group>
        </Col>
        <Col xs={6} sm={3}>
          <Form.Group controlId='distance'>
            <Form.Label style={{whiteSpace: 'no-wrap'}}>Distance ran</Form.Label>
            <Form.Control
              type='number'
              required
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
              isInvalid={distanceError}
            />
            {distanceError &&
              <Form.Control.Feedback type='invalid'>
                Run distance is required and must be a number.
              </Form.Control.Feedback>
            }
          </Form.Group>
        </Col>
        <Col xs={6} sm={3}>
          <Form.Group controlId='unit'>
            <Form.Label>&nbsp;</Form.Label>
            <InputGroup>
              <Form.Control
                as='select'
                custom
                required
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              >
                <option value='mi'>miles</option>
                <option value='km'>km</option>
              </Form.Control>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group controlId='notes'>
        <Form.Label>Notes (optional)</Form.Label>
        <Form.Control
          type='text'
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </Form.Group>
      <Button
        type='submit'
        block
      >
        {_id
          ? 'Edit Run'
          : 'Add Run'
        }
      </Button>
      {_id &&
        <Button
          size='sm'
          variant='danger'
          className='delete-button'
          onClick={handleDelete}
        >
          Delete Run
        </Button>
      }
    </Form>
  );

}
