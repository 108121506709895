export const getRuns = () => {
  return fetch('/api/runs').then((res) => res.json());
}

export const addRun = ({ date, distance, unit, notes }) => {
  return fetch('/api/runs/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({date, distance, unit, notes}),
  })
    .then((res) => res.json())
  ;
}

export const editRun = ({ _id, date, distance, unit, notes }) => {
  return fetch('/api/runs/', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({_id, date, distance, unit, notes}),
  })
    .then((res) => res.json())
  ;
}

export const deleteRun = (_id) => {
  return fetch('/api/runs/', {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({_id}),
  })
    .then((res) => res.json())
  ;
}
