import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Card, Form, Button } from 'react-bootstrap';
import { isEmail } from 'validator';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { registerUser } from '../api/user';
import { setAuthedUser } from '../actions/authedUser';
import logger from '../lib/logger';



export default function RegistrationForm (props) {

  const match = useRouteMatch('/activate/:code');

  const code = match && match.params.code;

  const dispatch = useDispatch();
  const history = useHistory();

  // get the state provided when we get redirected here from the login form
  const {state} = useLocation();

  const [email, setEmail] = useState((state && state.email) || '');
  const [emailError, setEmailError] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    setEmailError(false);
    if (!email || !isEmail(email)) {
      return setEmailError('invalid');
    }

    registerUser({email, firstName, lastName, code})
      .then((response) => {
        switch (response.status) {
          case 'conflict':
            return setEmailError('alreadyUsed');
          case 'validationError':
            return setEmailError('invalid');
          case 'success':
            dispatch(setAuthedUser(response.user));
            return history.push('/profile');
          default:
            console.log(response);
            logger.push({error: 'error registering', email, firstName, lastName, response});
            return alert('An error occured');
        }
      })
      .catch((err) => {
        console.log(err);
        logger.push({error: 'error registering', email, firstName, lastName, err});
        alert('An error occured');
      })
    ;
  }


  return (
    <div className="auth-form">
      <header>
        <h1>Set up access to the run tracker</h1>
      </header>
      <Card>
        <Card.Body>
          <Form
            onSubmit={handleSubmit}
            noValidate
          >
            <Form.Group controlId='email'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type='email'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={emailError}
              />
              {emailError === 'invalid' &&
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid email address.
                </Form.Control.Feedback>
              }
              {emailError === 'alreadyUsed' &&
                <Form.Control.Feedback type='invalid'>
                  There is already an account with this email address<br />
                  <Link
                    to={{
                      pathname: '/',
                      state: {email},
                    }}
                  >Click here to log in.</Link>
                </Form.Control.Feedback>
              }
            </Form.Group>
            <Form.Group controlId='firstName'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type='text'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='lastName'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type='text'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>
            <p><small><strong>Tip:</strong> You don't need a password. Next time you'll be able to log in with just your email address.</small></p>
            <Button type='submit' block>Register</Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
