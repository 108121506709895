import { showLoading, hideLoading } from 'react-redux-loading-bar';



export const SET_LOADING = 'SET_LOADING';



function setLoading (isLoading) {
  return {
    type: SET_LOADING,
    isLoading,
  }
}

export function handleSetLoading (isLoading) {
  return (dispatch) => {
    if (isLoading) {
      dispatch(showLoading());
    } else {
      dispatch(hideLoading());
    }
    dispatch(setLoading(isLoading));
  }
}
