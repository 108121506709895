import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';

import authedUser from './authedUser';
import runs from './runs';
import loading from './loading';
import users from './users';
import log from './log';



export default combineReducers({
  authedUser,
  runs,
  loadingBar: loadingBarReducer,
  loading,
  users,
  log,
});
