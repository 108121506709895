import { applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

let middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(logger);
}

export default applyMiddleware(...middleware);
