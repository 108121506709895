import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';

import { handleSetLog } from '../actions/log';



export default function UsersList () {

  const match = useRouteMatch('/admin/users/:id');

  const id = match.params.id;

  const dispatch = useDispatch();

  const events = useSelector((store) => store.log);

  useEffect(() => {
    dispatch(handleSetLog(id));
  }, [dispatch]);

  if (events.length === 0) return null;

  const user = events[0].user

  return (
    <>
      <h2>Activity log for {user.email}</h2>
      <Table striped bordered size='sm'>
        <thead>
          <tr>
            <th>Event Type</th>
            <th>Date</th>
            <th>Related ID</th>
            <th>User Agent</th>
          </tr>
        </thead>
        <tbody>
          {events.map(({_id, event, createdAt, rel, ua}) => (
            <tr key={_id}>
              <td>{event}</td>
              <td>{moment(createdAt).format('MMM Do YYYY, h:mm a')}</td>
              <td>{rel || 'n/a'}</td>
              <td>{ua}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}
