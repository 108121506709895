import { getLog } from '../api/log';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import logger from '../lib/logger';



export const SET_LOG = 'SET_LOG';
export const CLEAR_LOG = 'CLEAR_LOG'



function setLog (events) {
  return {
    type: SET_LOG,
    events,
  }
}

export function clearLog () {
  return {
    type: CLEAR_LOG
  }
}

export function handleSetLog (id) {
  return (dispatch) => {
    dispatch(showLoading());
    dispatch(clearLog());
    return getLog(id)
      .then((response) => {
        dispatch(setLog(response.events));
        dispatch(hideLoading());
      })
      .catch((err) => {
        console.log(err);
        logger.push({error: 'error getting user log', err});
      })
    ;
  }
}
