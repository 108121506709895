import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { handleSetUsers } from '../actions/users';



export default function UsersList () {

  const dispatch = useDispatch();

  const users = useSelector((store) => store.users);

  useEffect(() => {
    dispatch(handleSetUsers());
  }, [dispatch]);

  return (
    <Table striped bordered size='sm'>
      <thead>
        <tr>
          <th>Email</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Registered</th>
          <th>Admin</th>
          <th>Log</th>
        </tr>
      </thead>
      <tbody>
        {users.map(({_id, email, firstName, lastName, createdAt, isAdmin}) => (
          <tr key={_id}>
            <td>{email}</td>
            <td>{firstName}</td>
            <td>{lastName}</td>
            <td>{moment(createdAt).format('MMM Do YYYY, h:mm a')}</td>
            <td>{isAdmin ? 'true' : 'false'}</td>
            <td><Link to={`/admin/users/${_id}`}>Log</Link></td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
