import React, { useState } from 'react';

import logger from '../lib/logger';



export default function TestLogging ({onDone, run}) {

  logger.push({message: 'logging tested'});

  return (
    <h1>Tested</h1>
  );

}
