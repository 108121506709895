export const registerUser = ({ email, firstName, lastName, code }) => {
  return fetch('/api/user/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({email, firstName, lastName, code}),
  })
    .then((res) => res.json())
  ;
}



export const loginUser = (email) => {
  return fetch('/api/user/login', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({email}),
  })
    .then((res) => res.json())
  ;
}



export const logoutUser = () => {
  return fetch('/api/user/logout')
    .then((res) => res.json())
  ;
}
