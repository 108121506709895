import React, { useState } from 'react';
import { Table, Card, Button, Modal } from 'react-bootstrap';
import { PlusCircleFill, PencilSquare } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import moment from 'moment'

import RunForm from './RunForm';



export default function RunsTable () {

  const runs = useSelector((store) => store.runs.runs.sort((a, b) => new Date(b.date) - new Date(a.date)));

  const [selectedRun, setSelectedRun] = useState(null);
  const [showRunForm, setShowRunForm] = useState(false);

  const handleEditRun = (e, run) => {
    e.preventDefault();
    setSelectedRun(run);
    setShowRunForm(true);
  }

  const closeFormModal = () => {
    setSelectedRun(null);
    setShowRunForm(false);
  }

  return (
    <>

      <Button
        variant='primary'
        size='lg'
        block
        onClick={() => setShowRunForm(true)}
      >
        Add Run <PlusCircleFill />
      </Button>

      <Table
        className='runs-table'
        size='sm'
      >
        <thead className='thead-light'>
          <tr>
            <th className='date-col'>Date</th>
            <th className='distance-col'>Distance</th>
            <th className='notes-col'>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {runs.map((run) => {
            const {_id, date, distance, unit, notes} = run;
            return (
              <tr key={_id}>
                <td>{date && moment(date).format('MMM D, YYYY')}</td>
                <td><strong>{distance} {unit}</strong></td>
                <td>
                  <span title={notes}>{notes}</span>
                </td>
                <td>
                  <a
                    onClick={(e) => handleEditRun(e, run)}
                    className='text-secondary'
                    href='#'
                    title='Edit this entry'
                  >
                    <PencilSquare />
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Modal
        show={showRunForm}
        onHide={closeFormModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedRun
              ? 'Edit Run'
              : 'Add Run'
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RunForm
            onDone={closeFormModal}
            run={selectedRun}
          />
        </Modal.Body>
      </Modal>

    </>
  );
}
