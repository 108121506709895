import {
  ADD_RUN,
  EDIT_RUN,
  DELETE_RUN,
  SET_RUNS,
} from '../actions/runs';



export default function runs (state = {total: {mi: 0, ki: 0}, runs: []}, action) {
  let runs;
  switch (action.type) {
    case SET_RUNS:
      return {
        runs: action.runs,
        total: calculateTotal(action.runs),
      };
    case ADD_RUN:
      runs = state.runs.concat([action.run]);
      return {
        runs,
        total: calculateTotal(runs),
      }
    case EDIT_RUN:
      runs = state.runs.filter((run) => run._id !== action.run._id).concat([action.run]);
      return {
        runs,
        total: calculateTotal(runs),
      }
    case DELETE_RUN:
      runs = state.runs.filter((run) => run._id !== action._id);
      return {
        runs,
        total: calculateTotal(runs),
      }
    default:
      return state;
  }
}



const calculateTotal = (runs) => {
  const total = runs.reduce((soFar, run) => {
    if (!run.distance) return soFar;
    if (run.unit === 'km') return soFar + run.distance * 0.62137119;
    return soFar + run.distance;
  }, 0);
  // https://stackoverflow.com/a/11832950
  return {
    mi: Math.round((total + Number.EPSILON) * 100) / 100,
    km: Math.round((total * 1.609344 + Number.EPSILON) * 100) / 100,
  };
};
