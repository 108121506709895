import { setAuthedUser } from './authedUser';
import { setRuns } from './runs';
import { handleSetLoading } from './loading';
import { getInitialData } from '../api/shared';
import logger from '../lib/logger';



export function handleInitialData () {
  return (dispatch) => {
    dispatch(handleSetLoading(true));
    return getInitialData()
      .then(({user, runs = []}) => {
        dispatch(setAuthedUser(user));
        dispatch(setRuns(runs));
        dispatch(handleSetLoading(false));
      })
      .catch((err) => {
        console.log(err);
        logger.push({error: 'error getting initial data', err});
      })
    ;
  }
}
