import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { getRuns, deleteRun as deleteRunAPI } from '../api/runs';
import logger from '../lib/logger';

export const ADD_RUN = 'ADD_RUN';
export const EDIT_RUN = 'EDIT_RUN';
export const DELETE_RUN = 'DELETE_RUN';
export const SET_RUNS = 'SET_RUNS';



export function addRun (run) {
  return {
    type: ADD_RUN,
    run,
  }
}

export function editRun (run) {
  return {
    type: EDIT_RUN,
    run,
  }
}

function deleteRun (_id) {
  return {
    type: DELETE_RUN,
    _id,
  }
}

export function handleDeleteRun (_id) {
  return (dispatch) => {
    dispatch(deleteRun(_id));
    deleteRunAPI(_id)
      .then((response) => {
        if (response.status !== 'success') alert('An error occured deleting the run.');
      })
      .catch((err) => {
        console.log(err);
        logger.push({error: 'error deleting run', err});
      })
    ;
  }
}

export function setRuns (runs) {
  return {
    type: SET_RUNS,
    runs,
  }
}

export function handleSetRuns () {
  return (dispatch) => {
    dispatch(showLoading());
    return getRuns()
      .then((response) => {
        dispatch(hideLoading());
        dispatch(setRuns(response.runs));
      })
      .catch((err) => {
        console.log(err);
        logger.push({error: 'error getting runs', err});
      })
    ;
  }
}
