import { getUsers } from '../api/users';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import logger from '../lib/logger';



export const SET_USERS = 'SET_USERS';



function setUsers (users) {
  return {
    type: SET_USERS,
    users,
  }
}

export function handleSetUsers () {
  return (dispatch) => {
    dispatch(showLoading());
    return getUsers()
      .then((response) => {
        dispatch(setUsers(response.users));
        dispatch(hideLoading());
      })
      .catch((err) => {
        console.log(err);
        logger.push({error: 'error getting users', err});
      })
    ;
  }
}
